document.addEventListener('DOMContentLoaded', (e) => {

	const blocks = document.querySelectorAll('.block--media-callout');
	
	function create_block_media_callout() {

		return {

			block: null,
			config: { rootMargin: '0px 0px', threshold: 0.5 },
			observer: null,
			autoObserver: null,

			init: function (block) {

				this.block = block;
				
				let video = this.block.querySelector('.video');
				
				if ( video && video.dataset.autoplay == 'true') {
					this.autoObserver = new IntersectionObserver( this.autoplay.bind(this), this.config );
					this.autoObserver.observe( this.block );
				}

				if ( this.block.classList.contains('media-video')) {
					this.handlers();
				}

				this.observer = new IntersectionObserver( this.animate.bind(this), this.config );
				this.observer.observe( this.block );
			},

			handlers: function () {

				const openHandler = () => {
					this.open();
				};

				let wrapper = this.block.querySelector('.video-wrapper');
				let backdrop = this.block.querySelector('.backdrop');
				
				wrapper.addEventListener('click', function() {
					openHandler();
				});

				backdrop.addEventListener('click', function() {
					openHandler();
				})
			},

			open: function () {

				const loadHandler = () => {
					this.load();
				};

				const unloadHandler = () => {
					this.unload();
				};

				const expandableDiv = this.block.querySelector('.video');
				const isExpanded = this.block.classList.contains('expanded');

				const windowLeft = window.innerWidth * 0.2;
				const windowTop = window.innerHeight * .21875;

				if (!isExpanded) {
					let rect = expandableDiv.getBoundingClientRect();

					if ( window.matchMedia('(min-width: 992px)').matches ) {
						expandableDiv.style.transform = `translate(${windowLeft - rect.left}px, ${windowTop - rect.top}px)`;
					} else {
						expandableDiv.style.transform = `translateY(${windowTop - rect.top}px)`;
					}
					
					loadHandler();

					document.body.style.overflow = 'hidden';

				} else {
					document.body.style.overflow = 'auto';
					expandableDiv.style.transform = 'translate(0, 0)';
					unloadHandler();
				}

				this.block.classList.toggle('expanded');


			},
			
			load: function () {

				const unloadHandler = () => {
					this.unload();
				};

				let player = this.block.querySelector('.video');
				let src = player.dataset.src;
				let type = player.dataset.type

				if ( type == 'vimeo' ) {
					let id = src.split('/').pop();

					player.innerHTML = `<button class="close">Close &times;</button><iframe src="https://player.vimeo.com/video/${id}?autoplay=1&muted=0" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;

				}

				let close = player.querySelector('.close');
				close.addEventListener('click', function() {
					unloadHandler();
				});
				
			},
		
			unload: function() {
				let player = this.block.querySelector('.video');
				player.innerHTML = '';
				
			},

			animate: function(entries) {

				entries.forEach(entry => {
					if (entry.isIntersecting) {
						this.block.classList.add('animate');
						this.observer.unobserve( this.block );
					}
				});
			},

			autoplay: function(entries) {

				entries.forEach(entry => {
					if (entry.isIntersecting) {
						let video = this.block.querySelector('.video');
						this.autoObserver.unobserve( this.block );

						let src = video.dataset.src;
						let type = video.dataset.type

						if ( type == 'vimeo' ) {
							let id = src.split('/').pop();

							video.innerHTML = `<iframe src="https://player.vimeo.com/video/${id}?autoplay=1&loop=1&autopause=0&muted=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;

						}
					}
				});
			},
			
			log: function() {
				console.log('test');
			}
		}
	}

	blocks.forEach( block => {
		const block_media_callout = create_block_media_callout();
		block_media_callout.init(block);
	});
});